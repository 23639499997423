import React, { useState } from "react";
import Style from "./AlertStrip.module.css";
import { InteractiveTooltip } from "@my-scoot/component-library-legacy";
import { InfoOutlined } from "@material-ui/icons";

const AlertStrip = ({ text, toolTipText }) => {
  const formattedToolTipText = toolTipText.replace(/<br\s*\/?>/g, "\n");
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCloseTooltip = () => setOpenTooltip(false);
  const handleOpenTooltip = () => setOpenTooltip(true);
  return (
    <div className={`${Style.wrapper} mt-2 mt-md-0`}>
      <img
        src="/assets/vectors/Listings/auto_mode.svg"
        alt="auto-mode"
        className={Style.icon}
      />
      <span>Note: </span>
      {text}
      <InteractiveTooltip
        open={openTooltip}
        totalSteps={0}
        title={
          <div className={Style.toolTipContainer}>{formattedToolTipText}</div>
        }
        primaryBtnText="Got it"
        onCloseClick={handleCloseTooltip}
        onPrimaryBtnClick={handleCloseTooltip}
      >
        <InfoOutlined className={Style.infoIcon} onClick={handleOpenTooltip} />
      </InteractiveTooltip>
    </div>
  );
};

export default AlertStrip;
