export const delink_integration = "users/integrations/delink";
export const get_integration_list = "users/integrations/list";
export const get_is_integration_connected = "users/integrations/status";
export const get_zoom_integration = "users/integrations/zoom/get";
export const set_integration_token = "host/tracking/tokens/update";
export const generate_wordpress_token = "users/integrations/wordpress/update";
export const get_wordpress_token = "users/integrations/wordpress/get";
export const get_shiprocket_details = "users/integrations/shiprocket/get";
export const connect_shiprocket = "users/shiprocket/connect";
export const initiate_calendar_connection =
  "users/calendar/validation/initiate";
