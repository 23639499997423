/**
 * @description This function reorders the List(sales/offering) based on the order of status
 * @param {*} list List (sales/offering)
 * @param {*} statusOrder List of Statuses
 * @returns List in the order of status
 */
export const reorderListingsBasedOnStatus = (list, statusOrder) => {
  let reorderedList = [];
  for (let i = 0; i < statusOrder.length; i++) {
    const tempList = list.filter((item) => +item.status === statusOrder[i]);
    reorderedList = [...reorderedList, ...tempList];
  }
  return reorderedList;
};
