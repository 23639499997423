export const green_tick_application_steps = {
  GUIDE: 0,
  BASIC_DETAILS: 1,
  COMPANY_DETAILS: 2,
};

export const edit_business_profile =
  "https://business.facebook.com/wa/manage/phone-numbers/";

export const facebook_manager_docs =
  "https://www.facebook.com/business/help/338047025165344#types";

export const green_tick_status_consts = {
  SUBMITTED: 1,
  ACCEPTED: 2,
  REJECTED: 3,
};
