import React from "react";
import { Dropdown } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import styles from "./MerchandiseShippingStatusDropdownStyles.module.css";
import ShippingStatusOptions from "./modules/ShippingStatusOptions/ShippingStatusOptions";

const shipping_status = ShippingStatusOptions();
const dispatchedLabel = shipping_status?.find(
  (item) => item.value === constants.dispatchStatus.DISPATCHED
)?.label;

const MerchandiseShippingStatusDropdown = (props) => {
  const { record, onChange, hide } = props;
  const selectedDispatchStatus = parseInt(record.dispatch_status);
  const isDispatched =
    selectedDispatchStatus === constants.dispatchStatus.DISPATCHED;

  if (hide) return "-";
  return isDispatched ? (
    dispatchedLabel
  ) : (
    <Dropdown
      value={selectedDispatchStatus}
      handleChange={onChange}
      options={shipping_status}
      width="190px"
      restMenuProps={{ menu_paper_classname: styles.dropdownPopover }}
      menuItemClassName={styles.menuItem}
      inputBaseRootClassName={styles.inputBaseRootClassName}
      classes={{
        icon: styles.selectIcon,
      }}
    />
  );
};

export default MerchandiseShippingStatusDropdown;
