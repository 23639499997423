import {
  CUSTOM_CODE_STATUS_ENUMS,
  custom_code_activation_status,
} from "features/CustomCode/CustomCode.constants";
import React from "react";
import Tooltip from "ui/modules/Tooltip";

export const StatusField = ({ record = {} }) => {
  return (
    <div className={`d-flex align-items-start`}>
      {CUSTOM_CODE_STATUS_ENUMS[record.status].label}{" "}
      {record.status === custom_code_activation_status.IN_MODERATION && (
        <Tooltip
          className={`ml-1`}
          text={"It can take upto 48 hours for the changes to reflect."}
        />
      )}
      {record.status === custom_code_activation_status.REJECTED && (
        <Tooltip
          className={`ml-1`}
          text={
            "Your code was deactivated automatically due to security concerns."
          }
        />
      )}
    </div>
  );
};
