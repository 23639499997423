import { useEffect, useState } from "react";
import { logError } from "utils/error";
import { useToggleState } from "utils/hooks";
import { getAllTags } from "webpage-leads/webpageHelpers";

const useTags = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, startLoading, stopLoading] = useToggleState(false);

  useEffect(() => {
    const handleFetchTags = async () => {
      try {
        startLoading();
        const { data } = await getAllTags();
        setTags(data);
      } catch (error) {
        logError({
          error,
          occuredAt:
            "src/ui/modules/AddCustomerModal/modules/AdvancedDetails/modules/TagsSection/modules/hooks/useTags/useTags.js",
          when: "calling handleFetchTags",
        });
      } finally {
        stopLoading();
      }
    };

    handleFetchTags();
  }, []);

  return { isLoading, tags };
};

export default useTags;
