export const customer_table_config = {
  customer_name: {
    field: "customer_name",
    placeholder: "Customer Name",
    filterField: "guest_name",
    filterPlaceholder: "Name",
  },
  customer_email: {
    field: "customer_email",
    placeholder: "Customer Email",
    filterPlaceholder: "Email",
  },
  email: { field: "email", placeholder: "Customer Email" },
  phone_number: { field: "phone_number", placeholder: "Phone" },
  total_spent: { field: "total_spent", placeholder: "Total Spent" },
  transactions_count: {
    field: "transactions_count",
    placeholder: "No. of Transactions",
  },
  referer_group_id: { field: "referer_group_id", placeholder: "Source" },
  last_transaction_date: {
    field: "last_transaction_date",
    placeholder: "Last Transaction date",
  },
  tags: { field: "tags", placeholder: "Tags" },
  source_link: { field: "source_link", placeholder: "Source Link" },
  answer_count: { field: "answer_count", placeholder: "Q/A responses" },
  country: { field: "country", placeholder: "Country" },
  utm_info: { field: "utm_info", placeholder: "UTM info" },
  action: { field: "action", placeholder: "Actions" },
};

export const ALL_CUSTOMERS_ROUTE_KEYS = {
  CUSTOMERS_LIST: "customers",
};

export const ALL_CUSTOMERS_ROUTE_IDS = {
  [ALL_CUSTOMERS_ROUTE_KEYS.CUSTOMERS_LIST]: "payments/exly/customers/list/v2",
};

export const ALL_CUSTOMERS_LEARN_MORE_HREFS = {
  ALL_CUSTOMERS:
    "https://support.exlyapp.com/support/solutions/articles/84000347725-all-customers-tool-on-exly",
  ALL_CUSTOMERS_VIDEO: "https://www.youtube-nocookie.com/embed/1_LnY1Vh2DU",
};
