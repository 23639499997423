import { useEffect, useState } from "react";
import { logError } from "utils/error";
import { useToggleState } from "utils/hooks";
import { fetchPaymentModes } from "../../../PaymentModeFields.apiCalls";

const usePaymentModes = () => {
  const [paymentModes, setPaymentModes] = useState([]);
  const [loading, startLoading, stopLoading] = useToggleState(false);

  useEffect(() => {
    const handleFetchPaymentModes = async () => {
      try {
        startLoading();
        setPaymentModes(await fetchPaymentModes());
      } catch (error) {
        logError({
          error,
          occuredAt:
            "src/ui/modules/AddCustomerModal/modules/AdvancedDetails/modules/PaymentModeFields/modules/hooks/usePaymentModes/usePaymentModes.js",
          when: "calling handleFetchPaymentModes",
        });
      } finally {
        stopLoading();
      }
    };

    handleFetchPaymentModes();
  }, []);

  return { loading, paymentModes };
};

export default usePaymentModes;
