export const PLANS_APIS = {
  add_plan: "host/create/dropdown/plan",
  update_plan: "host/update/dropdown/plan",
  publish_plan: "host/publish/dropdown/plan",
  delete_plan: "host/delete/dropdown/plan",
  get_plans: "host/list/dropdown/plan",
  get_plan_details: "host/dropdown",
  duplicate_plan: "host/duplicate/dropdown/plan",
  update_listing_description: "host/update/listing/description",
  seo_plan: "host/dropdown",
};

export const PLAN_API_KEYS = {
  plan_uuid: "plan_uuid",
};
