import { INSTALLMENT_TYPES } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/PaymentPlanWithInstallments.data";
import { ORDER_PAYMENT_TYPE } from "schedule-v2/constants";

export const ADD_CUSTOMER_INSTALMENT_FIELD_KEYS = {
  payment_type: "payment_type",
  installment_uuid: "installment_uuid",
  installment_price: "installment_price",
};

export const ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS = {
  is_marked_paid_on_front_end: "is_marked_paid_on_front_end", // denotes if instalment marked paid in front end form data, using this to maintain instalment form data and it's disabled state on ui
};

export const INSTALMENT_PAYMENT_TYPES = {
  [INSTALLMENT_TYPES.INITIAL_PAYMENT]: ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT,
  [INSTALLMENT_TYPES.INSTALLMENT_CYCLE]:
    ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS,
};
