import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";

const useOfferingAutoDebitEnabled = () => {
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const isOfferingAutoDebitEnabled =
    featuresActivationStatus[feature_activation_keys.is_auto_debit_enabled.key];

  return { loading, isOfferingAutoDebitEnabled };
};

export default useOfferingAutoDebitEnabled;
