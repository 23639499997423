import arrayMutators from "final-form-arrays";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { is_empty } from "utils/validations";
import { INITIAL_DATA_TYPE } from "../constants/AddCustomer.constants";
import AddCustomerFormContent from "../modules/AddCustomerFormContent/AddCustomerFormContent";
import { useAddCustomer } from "../utils/useAddCustomer";
import { getInitialValuesOnType } from "../utils/AddCustomer.utils";

const AddCustomerModal = (props) => {
  const {
    onClose,
    listing_data: listingDataProp,
    entity_data: entityDataProp,
    onSuccess,
  } = props;
  const { handleAddBooking } = useAddCustomer({ onClose, onSuccess });
  const initialDataType =
    entityDataProp && !is_empty(entityDataProp.uuid)
      ? INITIAL_DATA_TYPE.PLAN
      : INITIAL_DATA_TYPE.LISTING;
  const [initialFormValues] = useState(
    getInitialValuesOnType(initialDataType, {
      listing_data: listingDataProp,
      entity_data: entityDataProp,
    })
  );

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={handleAddBooking}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <AddCustomerFormContent
              {...props}
              handleSubmit={handleSubmit}
              initialDataType={initialDataType}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default AddCustomerModal;
