const QuestionTypeValidationConstants = {
  form_fields: {
    number: {
      set_limits: "set_number_validations",
      min: "questionObj.validations.min",
      max: "questionObj.validations.max",
    },
  },
};

export default QuestionTypeValidationConstants;
