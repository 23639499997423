import constants from "constants/constants";
import country_codes from "constants/countryCodes";
import { PAYMENT_PLAN_TYPE } from "constants/schedule";
import { ASIA_KOLKATA_TIMEZONE } from "constants/timezones/AllTimezonesArray";
import moment from "moment";
import {
  getUserCountryCode,
  getUserCurrency,
  getUserTimezone,
  isInternationalTimezone,
} from "utils/AuthUtil";
import { is_class } from "utils/Utils";
import { is_empty } from "utils/validations";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { isClassType } from "utils/listing/listingUtils";
import { INITIAL_DATA_TYPE } from "../constants/AddCustomer.constants";
import { ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS } from "../modules/InstalmentsField/modules/InstalmentField/InstalmentField.constants";
import { isDateToday } from "features/Common/modules/DateTime/DateTime.utils";

const getInitialListingValues = (listing_data) => {
  const userCountryCode = getUserCountryCode();
  const available_currencies = [getUserCurrency()];
  if (!isInternationalTimezone() && listing_data?.price_international > 0) {
    available_currencies.push(constants.usd);
  }
  let selected_batch = "";
  if (is_class(listing_data)) {
    selected_batch = listing_data?.classes[0].uuid;
  }
  return {
    full_name: "",
    email: "",
    phone: "",
    country_code: userCountryCode,
    country: country_codes.filter(
      (item) => item.dial_code === userCountryCode
    )[0].name,
    listing_uid: listing_data?.uuid,
    amount: parseFloat(1 * listing_data?.price_per_head),
    send_comms: false,
    selected_quantity: 1,
    selected_price: listing_data?.price_per_head,
    currency: getUserCurrency(),
    selected_appointment_slots: [
      { date_index: 0, time: "", uuid: "", index: 0 },
    ],
    appointment_slots: [],
    selected_batch,
    available_currencies,
    [ADD_CUSTOMER_FORM_KEYS.email]: "",
    customer_phone_number: "",
    listing_data,
    payment_type: PAYMENT_PLAN_TYPE.ONE_TIME_PAYMENT,
    [ADD_CUSTOMER_FORM_KEYS.full_name]: "",
    [ADD_CUSTOMER_FORM_KEYS.address_details]: "",
    [ADD_CUSTOMER_FORM_KEYS.customer_timezone]: ASIA_KOLKATA_TIMEZONE,
    [ADD_CUSTOMER_FORM_KEYS.booking_start_date]:
      isClassType(listing_data?.type) && listing_data?.start_date
        ? listing_data.start_date
        : new Date(),
  };
};

const getInitialPlanValues = (entity_data, listing_data) => {
  const userCountryCode = getUserCountryCode();
  const available_currencies = [getUserCurrency()];
  if (!isInternationalTimezone() && entity_data?.max_price_international > 0) {
    available_currencies.push(constants.usd);
  }
  let selected_batch = "";
  return {
    full_name: "",
    email: "",
    phone: "",
    country_code: userCountryCode,
    country: country_codes.filter(
      (item) => item.dial_code === userCountryCode
    )[0].name,
    entity_uuid: entity_data?.uuid,
    amount: parseFloat(1 * entity_data?.max_price_per_head),
    send_comms: false,
    selected_quantity: 1,
    selected_price: entity_data?.max_price_per_headprice_per_head,
    currency: getUserCurrency(),
    customer_timezone: null,
    selected_appointment_slots: [
      { date_index: 0, time: "", uuid: "", index: 0 },
    ],
    appointment_slots: [],
    selected_batch,
    available_currencies,
    [ADD_CUSTOMER_FORM_KEYS.email]: "",
    customer_phone_number: "",
    listing_data,
    entity_data,
    [ADD_CUSTOMER_FORM_KEYS.full_name]: "",
    [ADD_CUSTOMER_FORM_KEYS.address_details]: "",
    [ADD_CUSTOMER_FORM_KEYS.customer_timezone]: ASIA_KOLKATA_TIMEZONE,
  };
};

export const getInitialValuesOnType = (type, { listing_data, entity_data }) => {
  switch (type) {
    case INITIAL_DATA_TYPE.LISTING:
      return getInitialListingValues(listing_data);
    case INITIAL_DATA_TYPE.PLAN:
      return getInitialPlanValues(entity_data, listing_data);
    default:
      return getInitialListingValues(listing_data);
  }
};

export const getInstalmentToMarkAsPaid = (instalments) => {
  if (is_empty(instalments)) return [];

  const [initialPaymentInstalment, ...restInstalments] = instalments;
  const instalmentsToMarkPaid = [initialPaymentInstalment];

  restInstalments.forEach((instalment) => {
    const {
      [ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end]:
        isInstalmentMarkedPaidOnFrontEnd,
    } = instalment;

    if (isInstalmentMarkedPaidOnFrontEnd) {
      const updatedInstalment = { ...instalment };
      delete updatedInstalment[
        ADD_CUSTOMER_INSTALMENT_FIELD_FRONT_END_KEYS.is_marked_paid_on_front_end
      ];
      instalmentsToMarkPaid.push(updatedInstalment);
    }
  });

  return instalmentsToMarkPaid;
};

// TODO: replace moment with date-fns
export const isTimeExpired = (time, basis) => {
  return moment(time).tz(getUserTimezone()).isBefore(moment(), basis);
};

/**
 * Formats the booking start date/time based on whether it's today's date.
 * If the booking start date is today, it sets the time to current time.
 * @param {string} bookingStartDate - The booking start date string to format.
 * @returns {Date|string} Returns a Date object with formatted date/time if today, otherwise returns the original bookingStartDate string.
 */
export const formatBookingStartDate = (bookingStartDate) => {
  if (isDateToday(bookingStartDate)) {
    const currentDate = new Date();
    const bookingStartDateTime = new Date(bookingStartDate);

    bookingStartDateTime.setHours(currentDate.getHours());
    bookingStartDateTime.setMinutes(currentDate.getMinutes());
    bookingStartDateTime.setSeconds(currentDate.getSeconds());

    return bookingStartDateTime;
  }

  return bookingStartDate;
};

export const FORMATTERS = {
  listing_options: (options) =>
    options?.map((list) => ({
      ...list,
      label: list.title,
      value: list.uuid,
    })),
};
