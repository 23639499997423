import React from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { is_empty } from "utils/validations";
import classnames from "classnames";
import styles from "./LinkFieldStyles.module.css";

const LinkField = ({ record = {}, source }) => {
  const { notify } = useNotifications();
  const isEmpty = is_empty(record[source]);

  const link =
    isEmpty || record[source].includes("http")
      ? record[source]
      : `https://${record[source]}`;

  if (isEmpty) return "-";

  return (
    <>
      <a
        href={link}
        className={classnames(`action_text ellipsis`, styles.linkStyle)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record[source]}
      </a>
      <FileCopyIcon
        onClick={() => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(link || "");
            notify("Link Copied!", notification_color_keys.info);
          }
        }}
        className={styles.copyIconStyle}
      />
    </>
  );
};

export default LinkField;
