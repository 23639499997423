// if: lines of code > 300

// then: breakdown this file and move to src/features/PricingSection/constants folder
export const PAYMENT_DEBIT_MODES = {
  PAYMENT_LINKS: 1,
  AUTO_DEBIT: 2,
};

export const PAYMENT_DEBIT_MODES_LABELS = {
  [PAYMENT_DEBIT_MODES.PAYMENT_LINKS]: "Payment Link",
  [PAYMENT_DEBIT_MODES.AUTO_DEBIT]: "Auto - debit",
};

export const PAYMENT_DEBIT_MODE_OPTIONS = [
  {
    label: PAYMENT_DEBIT_MODES_LABELS[PAYMENT_DEBIT_MODES.PAYMENT_LINKS],
    value: PAYMENT_DEBIT_MODES.PAYMENT_LINKS,
  },
  {
    label: PAYMENT_DEBIT_MODES_LABELS[PAYMENT_DEBIT_MODES.AUTO_DEBIT],
    value: PAYMENT_DEBIT_MODES.AUTO_DEBIT,
  },
];

export const price_content_msg = {
  international:
    "You can set different prices for American and International customers.",
  domestic:
    "You can set different prices for Indian and International customers.",
};

export const GST_STATUS_CONSTS = {
  INCLUSIVE: 1,
  EXCLUSIVE: 2,
  INAPPLICABLE: 3,
};

export const MIN_PRICING = 0;
export const MIN_GST_PERCENTAGE = 0;
export const MIN_SUBS_DAYS_DURATION_VALUE = 7;
export const MIN_SUBS_DURATION_VALUE = 1;
