import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import CustomerStatusModal from "ui/pages/customers/ManageBookings/ListingCustomers/components/CustomerStatusModal";
import { Switch } from "@my-scoot/component-library-legacy";
import { customer_questions_constants } from "constants/customerQuestions.constants";
import { change_message_initial_state } from "ui/pages/customers/ManageBookings/constants";
import useCustomerStatus from "./utils/useCustomerStatus";

const CustomerStatus = ({ record = {} }) => {
  const {
    checkedState,
    alert,
    isModalActive,
    handleChangeSwitch,
    onModalClose,
    onChangeCustomerStatus,
  } = useCustomerStatus(record);

  if (
    record.customer_schedule_status ===
    customer_questions_constants.customer_schedule_status.scheduled
  )
    return <span className="text-warning">Scheduled</span>;

  return (
    <>
      <span
        className={`${
          !checkedState ? "text-danger" : "text-success"
        } d-flex align-items-center`}
      >
        <>
          {!checkedState ? "Action Required" : "Completed"}
          <Switch
            checked={checkedState}
            size="large"
            onChange={handleChangeSwitch}
            color={"success"}
          />
        </>
      </span>
      {isModalActive && (
        <CustomerStatusModal
          key={"CreateWebsiteSuccessModal"}
          open={isModalActive}
          onClose={onModalClose}
          onSubmit={onChangeCustomerStatus}
        />
      )}
      {alert && (
        <ExlyModal
          title="Confirm Action"
          header="Confirm Action"
          open={alert}
          onClose={onModalClose}
          primaryBtnText={"Confirm"}
          onPrimaryBtnClick={() =>
            onChangeCustomerStatus(change_message_initial_state)
          }
        >
          <div className="p-3">
            Are you sure you want to change customer status?
          </div>
        </ExlyModal>
      )}
    </>
  );
};

export default CustomerStatus;
