import {
  ALL_CUSTOMERS_ROUTE_IDS,
  ALL_CUSTOMERS_ROUTE_KEYS,
} from "../AllCustomers.constants";

export const ALL_CUSTOMERS_RESPONSE_API = {
  [ALL_CUSTOMERS_ROUTE_IDS[ALL_CUSTOMERS_ROUTE_KEYS.CUSTOMERS_LIST]]: ({
    listData,
  }) => {
    for (let i = 0; i < listData.length; i++) {
      let data = listData[i];
      data.id = data.username;
    }
    return { data: listData };
  },
};
