import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import React from "react";
import styles from "./SectionContainer.module.css";

const SectionContainer = ({ label, children }) => {
  return (
    <div>
      <div className={styles.titleContainer}>
        <PlayArrowRoundedIcon />
        <div className={styles.label}>{label}</div>
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default SectionContainer;
