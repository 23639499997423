import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";
import { app_route_ids, app_route_keys } from "constants/urlPaths";

const Integration = React.lazy(() =>
  import("features/Integration/Integration")
);

const IntegrationDetails = React.lazy(() =>
  import("features/Integration/modules/IntegrationDetails/IntegrationDetails")
);

export const INTEGRATION_ROUTING_KEY = {
  integration: "integration_page",
  integration_details: "integration_details",
};

export const INTEGRATION_ROUTE_IDS = {
  [INTEGRATION_ROUTING_KEY.integration]: "/Integrations",
  [INTEGRATION_ROUTING_KEY.integration_details]: "/Integrations/:integrationId",
};

export const INTEGRATION_ROUTES_GENERATORS = {
  [INTEGRATION_ROUTING_KEY.integration_details]: ({ slug }) => {
    return `${app_route_ids[app_route_keys.integrations]}/${slug}`;
  },
};

export const INTEGRATION_CUSTOM_ROUTES = [
  <Route
    exact
    key={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTING_KEY.integration]}
    path={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTING_KEY.integration]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={Integration} props={props} />
    )}
  />,
  <Route
    exact
    key={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTING_KEY.integration_details]}
    path={INTEGRATION_ROUTE_IDS[INTEGRATION_ROUTING_KEY.integration_details]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={IntegrationDetails} props={props} />
    )}
  />,
];
