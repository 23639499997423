import { maxLength, required } from "common/validate";
import { GSTIN_REGEX } from "features/Common/constants/regExpPatterns";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { composeValidators, is_empty } from "utils/validations";
import { GST_FIELD_DATA, GST_FIELD_LABELS } from "./GSTFields.constants";
import { BILLING_STATE_VALUE_LABEL_MAP } from "features/Common/constants/billingConstants";

export const getStateCodeFromGSTIN = (gstin) => {
  // The first 2 digits of the 15 digit GSTIN represents the state code
  const stateCode = +gstin.substr(0, 2);

  return BILLING_STATE_VALUE_LABEL_MAP[stateCode] ? stateCode : null;
};

const validateGSTINFormat = (gstin) => {
  const effectiveGstin = gstin?.trim();

  if (!GSTIN_REGEX.test(effectiveGstin))
    return "GSTIN entered doesn't comply with the standard format";

  if (
    is_empty(
      BILLING_STATE_VALUE_LABEL_MAP[getStateCodeFromGSTIN(effectiveGstin)]
    )
  )
    return "State code in GSTIN is incorrect";
};

const validateStateWithGSTIN = (billingState, formValues) => {
  const {
    [ADD_CUSTOMER_FORM_KEYS.customer_gst_details]: customerGSTDetails = {},
  } = formValues;
  const { [ADD_CUSTOMER_FORM_KEYS.gst_number]: gstin } = customerGSTDetails;

  if (gstin) {
    if (is_empty(billingState))
      return "When GSTIN is entered, the state becomes mandatory";

    const billingStateCodeInGSTIN = getStateCodeFromGSTIN(gstin);

    if (billingStateCodeInGSTIN && billingState !== billingStateCodeInGSTIN) {
      return "Billing state is different from billing state in GSTIN";
    }
  }
};

export const GST_FIELDS_FORM_VALIDATORS = {
  [ADD_CUSTOMER_FORM_KEYS.billing_state]: ({ required: isRequired }) =>
    composeValidators(
      isRequired ? required : undefined,
      validateStateWithGSTIN
    ),

  [ADD_CUSTOMER_FORM_KEYS.gst_number]: composeValidators(
    required,
    validateGSTINFormat
  ),

  [ADD_CUSTOMER_FORM_KEYS.gst_name]: composeValidators(
    maxLength(
      GST_FIELD_DATA.gst_name_max_length,
      `${GST_FIELD_LABELS[ADD_CUSTOMER_FORM_KEYS.gst_name]} should be within ${
        GST_FIELD_DATA.gst_name_max_length
      } characters`
    )
  ),
};

export const GST_FIELDS_FORM_PARSERS = {
  [ADD_CUSTOMER_FORM_KEYS.gst_number]: (value) => value && value.toUpperCase(),
};
