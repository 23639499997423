export const DISABLE_CUSTOM_CODE_UPDATION_IN_MODERATION_MODE =
  "Cannot update custom code in moderation mode.";

export const custom_code_activation_status = {
  ACTIVE: 1,
  INACTIVE: 2,
  ARCHIVE: 3,
  IN_MODERATION: 4,
  REJECTED: 5,
};

export const CUSTOM_CODE_STATUS_ENUMS = {
  [custom_code_activation_status.ACTIVE]: {
    value: custom_code_activation_status.ACTIVE,
    label: "Active",
  },
  [custom_code_activation_status.INACTIVE]: {
    value: custom_code_activation_status.INACTIVE,
    label: "Inactive",
  },
  [custom_code_activation_status.ARCHIVE]: {
    value: custom_code_activation_status.ARCHIVE,
    label: "Deactivated",
  },
  [custom_code_activation_status.IN_MODERATION]: {
    value: custom_code_activation_status.IN_MODERATION,
    label: `Deployment${"\u00A0"}in${"\u00A0"}progress`,
  },
  [custom_code_activation_status.REJECTED]: {
    value: custom_code_activation_status.REJECTED,
    label: "Deactivated",
  },
};

export const COLUMN_KEYS = {
  status: { key: "status", label: "Status" },
};

export const LOCKED_KEY = "locked"; // in integration config

// allowed tags meta, link, script, noscript, style, iframe,
export const CUSTOM_CODE_ALLOWED_TAGS_REGEX =
  /^((<meta[,\w\d\s"'#=-]*?\/?>)|(<link[^<^>]*?\/>)|(<script[^<^>]*>[\s\S]*<\/script>)|(<noscript[^<^>]*>[\s\S]*<\/noscript>)|(<style[^<^>]*>[\s\S]*<\/style>)|(<iframe[^<^>]*>[\s\S]*<\/iframe>)|\s)*$/;
