import { useEffect, useState } from "react";
import { fetchModalData } from "./utils/BookingDetails.utils";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { logError } from "utils/error";

const useBookingDetailsData = ({ hideModal, record }) => {
  const [data, setData] = useState({});

  const { notify } = useNotifications();

  useEffect(() => {
    const updateModalData = async () => {
      try {
        const data = await fetchModalData({ uuid: record.uuid });
        if (!data) {
          hideModal();
        } else {
          setData((prev) => ({ ...prev, ...data }));
        }
      } catch (err) {
        hideModal();
        notify(err.message, notification_color_keys.error);
        logError({
          error: err.message,
          occuredAt: "src/ui/pages/schedule/BookedSession/BookedSession.js",
          when: "Error while fetching booking details",
        });
      }
    };

    updateModalData();
  }, [record]);

  return { data };
};

export default useBookingDetailsData;
