import React from "react";
// ICONS
import StorefrontIcon from "@material-ui/icons/Storefront";

export const google_calendar = "google_calendar";
export const facebook_pixel = "facebook_pixel";
export const google_analytics = "google_analytics";
export const domain_linking = "domain_linking";
export const wordpress = "wordpress";
export const pabbly = "pabbly";
export const zoom = "zoom";
export const custom_code = "custom_code";
export const zapier = "zapier";
export const shiprocket = "shiprocket";
// Comming Soon
export const whatsapp = "whitelabelled_whatsapp";
export const wix = "wix";

export const INTEGRATIONS_PAGE_TITLE = "Integration";

export const INTEGRATIONS_LEARN_MORE_HREFS = {
  INTEGRATIONS: "https://support.exlyapp.com/support/solutions/84000149953",
};

export const PLUGIN_URLS = {
  [wordpress]: "https://wordpress.org/plugins/exly-wp/",
  [zoom]: "https://marketplace.zoom.us/apps/LPcE_wxVRfavPBNaaID14w",
  [zapier]: "https://zapier.com/",
  [shiprocket]: {
    signup: "https://app.shiprocket.in/register",
    configureApi: "https://app.shiprocket.in/api-user",
  },
};

export const INTEGRATIONS_STATUS_VALUE = {
  CONNECTED: 1,
  DISCONNECTED: 2,
  FEATURE_LOCK: 3,
  PLAN_LOCK: 4,
  COMING_SOON: 5,
};

export const integration_cards_keys = {
  slug: "slug",
  syncText: "syncText",
  learn_more: "learn_more",
  learn_more_href: "learn_more_href",
  description: "description",
  image: "image",
  status: "status",
  title: "title",
};

// integrations meta data which is used in integration card page.
export const integrations_cards = {
  [zapier]: {
    slug: zapier,
    syncText: "Connected",
    learn_more: "Connect Zapier",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392241-integrate-zapier-with-exly",
  },
  [pabbly]: {
    slug: pabbly,
    syncText: "Connected",
    learn_more: "Connect Pabbly",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392240-integrate-pabbly-with-exly",
  },
  [whatsapp]: {
    slug: whatsapp,
    learn_more: "Connect WhatsApp",
    syncText: "Connected",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000392245-how-to-white-label-your-whatsapp-communications",
  },
  [wordpress]: {
    slug: wordpress,
    syncText: "Connected",
    pre_sync: {
      icon: <StorefrontIcon />,
      text: "Visit marketplace",
      redirect: PLUGIN_URLS[wordpress],
    },
    learn_more: "Connect Wordpress",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392239-integrate-wordpress-with-exly",
  },
  [shiprocket]: {
    slug: shiprocket,
    syncText: "Connected",
    learn_more: "Add Shiprocket",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392238-integrate-shiprocket-with-exly",
  },
  [google_calendar]: {
    slug: google_calendar,
    syncText: "Connected",
    learn_more: "Connect calendars",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000355034-integrate-google-calendar-with-exly",
  },
  [facebook_pixel]: {
    slug: facebook_pixel,
    syncText: "Connected",
    learn_more: "Connect Facebook Pixel",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000355064-integrate-facebook-pixel-with-exly",
  },
  [google_analytics]: {
    slug: google_analytics,
    syncText: "Connected",
    learn_more: "Connect Google Analytics",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000355066-integrate-google-analytics-with-exly",
  },
  [domain_linking]: {
    slug: domain_linking,
    name: "domainName",
    syncText: "Connected",
    learn_more: "Link your domain",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000354964-set-up-a-custom-domain-name-on-exly",
  },
  [zoom]: {
    slug: zoom,
    syncText: "Connected",
    pre_sync: {
      icon: <StorefrontIcon />,
      text: "Visit marketplace",
      redirect: PLUGIN_URLS[zoom],
    },
    learn_more: "Connect Zoom",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392237-integrate-zoom-with-exly",
  },
  [custom_code]: {
    slug: custom_code,
    syncText: "Integrated",
    learn_more: "Add Custom Code",
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392236-integrate-custom-code-with-exly",
  },
  // Comming Soon
  [wix]: {
    slug: wix,
  },
};
