import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { IconButton, Button } from "@my-scoot/component-library-legacy";
import classes from "./WhatsappButtonStyles.module.css";
import { onConnectWhatsapp } from "features/Listings/utils/Listings.utils";
import WhatsappIcon from "assets/images/whatsapp.png";

export const WhatsAppButton = ({
  record = {},
  source,
  countryCodeKey,
  showIconBtn = false,
}) => {
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );
  const isDesktop = useDesktopMediaQuery();

  const handleOnConnectWatsapp = () => {
    onConnectWhatsapp({ record, countryCodeKey, source });
  };

  if (isCustomerDetailsHidden || !record[source]) return null;

  return (
    <>
      {isDesktop || showIconBtn ? (
        <IconButton onClick={handleOnConnectWatsapp}>
          <img src={WhatsappIcon} className={classes.mobileWhatsappIcon} />
        </IconButton>
      ) : (
        <div className="p-3">
          <Button
            color="success"
            fullWidth="true"
            onClick={handleOnConnectWatsapp}
          >
            <span>
              <img src={WhatsappIcon} className={classes.mobileWhatsappIcon} />
            </span>
            <span></span>Connect WhatsApp
          </Button>
        </div>
      )}
    </>
  );
};
