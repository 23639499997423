import { api, dataProvider } from "data";
import { isRequestSuccessful } from "utils/Utils";

export const fetchPayoutDetails = async () => {
  const { data, status } = await dataProvider.custom_request(
    api.user_payout_details
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error fetching payout details: ${data} ${status}`);

  return data?.account_details;
};
