export const MODES_OF_PAYMENT = {
  OTHER: "other",
};

export const OTHER_OF_PAYMENT_OPTION = {
  label: "Other",
  value: MODES_OF_PAYMENT.OTHER,
};

export const NOTES_MAX_LENGTH = 500;
