import { dataProvider } from "data";
import { isRequestSuccessful } from "utils/Utils";
import { ADVANCED_DETAILS_APIS } from "../../AdvancedDetails.api";

export const fetchPaymentModes = async () => {
  const { data, status } = await dataProvider.custom_request(
    ADVANCED_DETAILS_APIS.get_payment_modes
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error fetching payment modes: ${data} ${status}`);

  return data?.payment_modes;
};
