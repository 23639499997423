import { ExlyDropdown, ExlyInput } from "common/form";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { MODES_OF_PAYMENT } from "../../AdvancedDetails.constants";
import { FORM_VALIDATORS } from "../../AdvancedDetails.utils";
import styles from "./PaymentModeFields.module.css";
import { getPaymentModesOptions } from "./PaymentModeFields.utils";
import usePaymentModes from "./modules/hooks/usePaymentModes/usePaymentModes";

const PaymentModeFields = () => {
  const {
    values: {
      [ADD_CUSTOMER_FORM_KEYS.payment_mode_uuid]: selectedModeOfPayment,
      [ADD_CUSTOMER_FORM_KEYS.payment_mode_name]: paymentModeName,
    },
  } = useFormState();
  const form = useForm();
  const { loading: isLoadingPaymentModes, paymentModes } = usePaymentModes();
  const isOtherModeOfPaymentSelected =
    selectedModeOfPayment === MODES_OF_PAYMENT.OTHER;

  const handleClearCustomPaymentModeName = (paymentModeUuidInput) => {
    if (paymentModeUuidInput !== MODES_OF_PAYMENT.OTHER && paymentModeName) {
      form.change(ADD_CUSTOMER_FORM_KEYS.payment_mode_name);
    }
  };

  const handleChangePaymentMode = (value) => {
    handleClearCustomPaymentModeName(value);
  };

  return (
    <>
      <Field
        name={ADD_CUSTOMER_FORM_KEYS.payment_mode_uuid}
        component={ExlyDropdown}
        options={getPaymentModesOptions(paymentModes)}
        onChange={handleChangePaymentMode}
        label="Mode of payment"
        placeholder="Select mode of payment"
        size="small"
        loading={isLoadingPaymentModes}
        fullWidth
        tall
      />

      {isOtherModeOfPaymentSelected ? (
        <div className={styles.other}>
          <Field
            name={ADD_CUSTOMER_FORM_KEYS.payment_mode_name}
            component={ExlyInput}
            placeholder="Enter other mode of payment"
            validate={FORM_VALIDATORS[ADD_CUSTOMER_FORM_KEYS.payment_mode_name]}
            fullWidth
          />
        </div>
      ) : null}
    </>
  );
};

export default PaymentModeFields;
