import { api } from "data";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { ADD_CUSTOMER_APIS } from "./AddCustomer.apis";

export const postAddCustomer = async (
  dataProvider,
  payload,
  isInstalmentsPlanTypeSelected
) => {
  const postApi = isInstalmentsPlanTypeSelected
    ? ADD_CUSTOMER_APIS.offline_instalment_type_payment
    : api.offline_payment;

  const { data, status } = await dataProvider.custom_request(
    postApi,
    apiMethods.POST,
    payload,
    { checkCreditsExpiry: true }
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error adding customer: ${data} ${status}`);

  return data;
};
