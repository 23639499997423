import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";

export const fetchModalData = async ({ uuid }) => {
  const endpoint = `${api.my_bookings_view_details}/${uuid}`;
  const method = apiMethods.GET;

  try {
    const response = await dataProvider.custom_request(endpoint, method);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
