import { useEffect, useState } from "react";
import { getUserChecks } from "utils/AuthUtil";
import { feature_activation_keys } from "../FeatureActivationStatus.constants";

/**
 * A custom hook that retrieves the activation status of all specified features for the creator.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Array<string>} [params.keys=[]] - An array of keys to check the activation status for.
 * @returns {Object} An object containing the loading status and feature activation checks.
 * - `loading` (boolean): Indicates whether the data is currently being loaded.
 * - `featuresActivationStatus` (Object): An object containing feature activation status with boolean values.
 */
const useFeaturesActivationStatus = () => {
  const featureActivationValues = {};
  for (const key in feature_activation_keys) {
    featureActivationValues[key] = feature_activation_keys[key].value;
  }
  const [featuresActivationStatus, setFeaturesActivationStatus] = useState(
    featureActivationValues
  );
  const [loading, setLoading] = useState(true);

  const handleFetchFeaturesChecks = async () => {
    try {
      setLoading(true);
      const checks = await getUserChecks();
      setFeaturesActivationStatus(checks);
    } catch (error) {
      console.log("Error fetching features activation status:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchFeaturesChecks();
  }, []);

  return { loading, featuresActivationStatus };
};

export default useFeaturesActivationStatus;
