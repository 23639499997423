import React from "react";
import { Avatar } from "@my-scoot/component-library-legacy";

import CloseIcon from "@material-ui/icons/Close";
import classes from "./DetailsHeaderStyles.module.css";
import { WhatsAppButton } from "../WhatsAppButton/WhatsappButton";
import constants from "ui/pages/schedule/BookedSession/bookedSession.constants";
import { listing_customer_country_code } from "features/Listings/Listings.constants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { is_empty } from "utils/validations";

const DetailsHeader = ({ data, hideModal }) => {
  const isDesktop = useDesktopMediaQuery();

  if (is_empty(data)) return <></>;
  return (
    <div className={classes.header}>
      <div className={classes.name_wrapper}>
        <Avatar label={data[constants.form_data.name.field]} />
        <span className="word_break">
          {data[constants.form_data.name.field] ?? "-"}
        </span>
      </div>
      <div className={classes.header_icons}>
        {isDesktop && (
          <WhatsAppButton
            record={data}
            source={constants.form_data.phone.field}
            countryCodeKey={listing_customer_country_code}
          />
        )}
        <div
          onClick={hideModal}
          className={`${classes.cross_icon_wrapper} pt-3 pb-3`}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default DetailsHeader;
