import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import {
  ActionField,
  DateTimeField,
  InternationalStatusField,
  WhatsAppPhoneField,
} from "common/Components/TableFields/TableFields";
import constants from "constants/constants";
import {
  PAYMENT_DEBIT_MODE_OPTIONS,
  PAYMENT_DEBIT_MODES_LABELS,
} from "features/Listings/modules/PricingSection/PricingSection.constants";
import GSTDetailsField from "features/Transactions/modules/GSTDetailsField/GSTDetailsField";
import React from "react";
import {
  ORDER_PAYMENT_TYPE,
  ORDER_PAYMENT_TYPE_LABELS,
} from "schedule-v2/constants";
import MoneyField from "ui/modules/MoneyField";
import {
  field_data,
  FILTERS_OPTIONS_PLACEHOLDERS,
  form_data,
  listingTypeOptions,
  PAYMENT_PLAN_TYPE_OPTIONS,
} from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import {
  OfferField,
  OfferingPlanNameField,
  PayoutFieldHeader,
  StatusField,
} from "ui/pages/transactions/AllTransaction/components/TableFields/TableFields";
import { getSignedS3DownlaodURL } from "utils/getAccessS3Url";
import { DateConvert } from "utils/Utils";
import { is_empty } from "utils/validations";
import {
  formKeys,
  GATEWAY_TYPE_FILTER_OPTIONS,
  TRANSACTION_TYPES,
} from "../Transactions.constants";
import classNames from "../Transactions.module.css";

export const getTransactionCustomerNameConfig = () => ({
  field: "customer_name",
  headerName: "Customer Name",
  avatar: true,
  isPrimary: true,
  isDrawerTitle: true,
  emptyField: "N/A",
  sortable: false,
  fixed: "left",
  width: "200px",
});

export const getTransactionCustomerPhoneConfig = ({
  isCustomerDetailsHidden,
}) => ({
  field: "customer_phone",
  headerName: "Phone",
  valueFormatter: (record) => (
    <WhatsAppPhoneField
      record={record}
      source="customer_phone"
      countryCodeKey="customer_country_code"
    />
  ),
  sortable: false,
  isCollapsed: true,
  hidden: isCustomerDetailsHidden,
  hiddenDesktopColumn: isCustomerDetailsHidden,
});

export const getTransactionCustomerEmailConfig = ({
  isCustomerDetailsHidden,
}) => ({
  field: "customer_email",
  headerName: "Email Id",
  emptyField: "N/A",
  sortable: false,
  width: "120px",
  columnClassName: "word_break",
  isCollapsed: true,
  hidden: isCustomerDetailsHidden,
  hiddenDesktopColumn: isCustomerDetailsHidden,
});

export const getTransactionOfferingOwnerConfig = () => ({
  field: "staff_name",
  headerName: (
    <>
      Offering Owner&nbsp;
      <Tooltip
        title="This has been assigned from member selected in the offering "
        color="primary"
        arrow
      >
        <InfoOutlinedIcon className={classNames.infoIconStyle} />
      </Tooltip>
    </>
  ),
  valueFormatter: (record) =>
    is_empty(record.staff_name) ? "N/A" : record.staff_name,
  sortable: false,
  isCollapsed: true,
  width: "70px",
});

export const getTransactionOfferingNameConfig = () => ({
  field: field_data.session_name,
  headerName: form_data.session_name,
  valueFormatter: (record) => <OfferingPlanNameField record={record} />,
  sortable: false,
  emptyField: "N/A",
  noWrap: true,
  ellipsis: true,
  maxCharCount: 30,
});

export const getTransactionOfferingTypeConfig = () => ({
  field: "session_type",
  headerName: "Offering Type",
  valueFormatter: (record) =>
    constants.schedule_type[record.session_type].name ?? "N/A",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
});

export const getTransactionPaymentTypeConfig = () => ({
  field: field_data.payment_type,
  headerName: form_data.payment_plan_type,
  valueFormatter: (record) => {
    const { payment_type } = record;
    return payment_type ? ORDER_PAYMENT_TYPE_LABELS[payment_type] : "N/A";
  },
  isCollapsed: true,
  noWrap: true,
});

export const getTransactionDebitTypeConfig = () => ({
  field: field_data.payment_debit_mode,
  headerName: form_data.payment_debit_mode,
  valueFormatter: (record) => {
    const { payment_type } = record;
    return payment_type === ORDER_PAYMENT_TYPE.SUBSCRIPTION
      ? PAYMENT_DEBIT_MODES_LABELS[record?.payment_debit_mode]
      : "N/A";
  },
  isCollapsed: true,
  noWrap: true,
});

export const getTransactionPricePerSlotConfig = ({ hidePricePerSlot }) => ({
  field: "price",
  headerName: "Price per spot",
  valueFormatter: (record) => (
    <MoneyField
      record={record}
      source="price"
      showGstHelper={true}
      creator_sales={false}
      noRightMargin={true}
    />
  ),
  sortable: false,
  hidden: hidePricePerSlot,
  hiddenDesktopColumn: hidePricePerSlot,
});

export const getTransactionAmountConfig = ({ hideTransactionAmount }) => ({
  field: "transaction_amount",
  headerName: "Transaction Amount",
  valueFormatter: (record) => (
    <MoneyField
      record={record}
      source="transaction_amount"
      showGstHelper={true}
      creator_sales={false}
      noRightMargin={true}
    />
  ),
  sortable: false,
  hidden: hideTransactionAmount,
  hiddenDesktopColumn: hideTransactionAmount,
});

export const getTransactionDiscountCodeConfig = ({ onClickOffer }) => ({
  field: "discount_code",
  headerName: "Discount Code",
  valueFormatter: (record) => (
    <OfferField record={record} source="discount_code" onClick={onClickOffer} />
  ),
  sortable: false,
  isCollapsed: true,
});

export const getTransactionLimitedTimeOfferConfig = ({ onClickOffer }) => ({
  field: "feature_discount_code",
  headerName: "Limited time offer",
  valueFormatter: (record) => (
    <OfferField
      record={record}
      source="feature_discount_code"
      onClick={onClickOffer}
    />
  ),
  sortable: false,
  isCollapsed: true,
});

export const getTransactionDiscountAmountConfig = () => ({
  field: "discount_amount",
  headerName: "Discount Amount",
  valueFormatter: (record) => (
    <MoneyField
      record={record}
      source="discount_amount"
      creator_sales={false}
      noRightMargin={true}
    />
  ),
  sortable: false,
  isCollapsed: true,
});

export const getTransactionPaymentModeConfig = () => ({
  field: field_data.payment_mode,
  headerName: FILTERS_OPTIONS_PLACEHOLDERS[field_data.payment_mode],
  emptyField: "N/A",
  sortable: false,
  isCollapsed: true,
});

export const getTransactionTransactionIdConfig = () => ({
  field: field_data.transaction_id,
  headerName: "Transaction ID",
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
});

export const getTransactionDateConfig = () => ({
  field: "created_at",
  headerName: "Transaction Date",
  valueFormatter: (record) => (
    <DateTimeField record={record} source="created_at" />
  ),
  sortable: false,
  noWrap: true,
});

export const getTransactionUnitsConfig = ({ hideNumberOfUnits }) => ({
  field: "guest_count",
  headerName: "No. of units",
  emptyField: "N/A",
  sortable: false,
  isCollapsed: true,
  hidden: hideNumberOfUnits,
  hiddenDesktopColumn: hideNumberOfUnits,
});

export const getTransactionInternationalConfig = () => ({
  field: "is_international",
  headerName: "International Transaction",
  valueFormatter: (record) => (
    <InternationalStatusField record={record} source="is_international" />
  ),
  sortable: false,
  isCollapsed: true,
});

export const getTransactionPaymentAmountConfig = ({ hidePaymountAmount }) => ({
  field: "payment_amount",
  headerName: "Payout Amount",
  valueFormatter: (record) => (
    <MoneyField
      record={record}
      source="payment_amount"
      isINR={true}
      noRightMargin={true}
    />
  ),
  sortable: false,
  isCollapsed: true,
  hidden: hidePaymountAmount,
  hiddenDesktopColumn: hidePaymountAmount,
});

export const getTransactionPayoutDateConfig = ({ hidePayoutDate }) => ({
  field: "payout_date",
  headerName: "Payout Date",
  renderHeader: () => <PayoutFieldHeader />,
  valueFormatter: (record) =>
    record.payout_date ? DateConvert(record, "payout_date") : "N/A",
  noWrap: true,
  sortable: false,
  isCollapsed: true,
  hidden: hidePayoutDate,
  hiddenDesktopColumn: hidePayoutDate,
});

export const getTransactionGSTInfoConfig = () => ({
  field: field_data.customer_gst_info,
  headerName: FILTERS_OPTIONS_PLACEHOLDERS[field_data.customer_gst_info],
  valueFormatter: (record) => <GSTDetailsField record={record} />,
  emptyField: "N/A",
  sortable: false,
  isCollapsed: true,
  width: "120px",
});

export const getTransactionBillingStateConfig = () => ({
  field: field_data.billing_state,
  headerName: FILTERS_OPTIONS_PLACEHOLDERS[field_data.billing_state],
  emptyField: "N/A",
  sortable: false,
  isCollapsed: true,
});

export const getTransactionCustomerInvoiceConfig = ({
  hideNumberOfUnits,
  isCustomerDetailsHidden,
}) => ({
  field: "receipt_url",
  headerName: "Customer Invoice",
  valueFormatter: (record) => (
    <ActionField
      label="View Invoice"
      record={record}
      onClick={({ record }) => getSignedS3DownlaodURL(record)}
    />
  ),
  noWrap: true,
  sortable: false,
  isCollapsed: true,
  hidden: hideNumberOfUnits || isCustomerDetailsHidden, // invoice shows customer details
  hiddenDesktopColumn: hideNumberOfUnits || isCustomerDetailsHidden,
});

export const getTransactionUTRInfoConfig = () => ({
  field: "utr_info",
  headerName: "UTR Info",
  emptyField: "N/A",
  sortable: false,
  isCollapsed: true,
});

export const getTransactionPayoutStatusConfig = ({ hidePayoutStatus }) => ({
  field: "payout_status",
  headerName: "Payout Status",
  valueFormatter: (record) => (
    <StatusField record={record} source="payout_status" />
  ),
  sortable: false,
  isCollapsed: true,
  hidden: hidePayoutStatus,
  hiddenDesktopColumn: hidePayoutStatus,
  noWrap: true,
  align: "right",
  fixed: "right",
});

export const getTransactionTypeConfig = () => {
  return {
    field: formKeys.transactionType.key,
    headerName: formKeys.transactionType.label,
    valueFormatter: (record) =>
      TRANSACTION_TYPES[record[formKeys.transactionType.key]]?.label,
    sortable: false,
    noWrap: true,
  };
};

export const getTransactionTypeFilterConfig = () => {
  return {
    source: formKeys.transactionType.key,
    placeholder: formKeys.transactionType.label,
    options: Object.values(TRANSACTION_TYPES).map((item) => ({
      label: item.label,
      value: item.value,
    })),
    type: "dropdown",
  };
};

export const getGuestNameFilterConfig = () => ({
  source: "guest_name",
  placeholder: "Customer Name",
  type: "input",
  disableContains: true,
});

export const getGuestEmailFilterConfig = () => ({
  source: "guest__email",
  placeholder: form_data.email,
  type: "input",
});

export const getListingTitleFilterConfig = () => ({
  source: "listing__title",
  placeholder: form_data.session_name,
  type: "input",
});

export const getGuestPhoneNumberFilterConfig = () => ({
  source: "guest__phone_number__contains",
  placeholder: form_data.phone,
  type: "input",
  disableContains: true,
});

export const getOfferingVariationFilterConfig = () => ({
  source: form_data.offeringVariation.filterKey,
  placeholder: form_data.offeringVariation.label,
  type: "input",
  disableContains: true,
});

export const getMinPriceFilterConfig = () => ({
  source: "min_price",
  placeholder: form_data.min_price,
  type: "input",
  disableContains: true,
});

export const getMaxPriceFilterConfig = () => ({
  source: "max_price",
  placeholder: form_data.max_price,
  type: "input",
  disableContains: true,
});

export const getMinCommissionPriceFilterConfig = () => ({
  source: "min_commission_amount",
  placeholder: "Min commission amount",
  type: "input",
  disableContains: true,
});

export const getMaxCommissionPriceFilterConfig = () => ({
  source: "max_commission_amount",
  placeholder: "Max commission amount",
  type: "input",
  disableContains: true,
});

export const getListingTypeFilterConfig = () => ({
  source: "listing__type",
  placeholder: form_data.type,
  options: listingTypeOptions,
  type: "dropdown",
  disableContains: true,
});

export const getPaymentTypeFilterConfig = () => ({
  source: "payment_type",
  placeholder: form_data.payment_plan_type,
  options: PAYMENT_PLAN_TYPE_OPTIONS,
  type: "dropdown",
  disableContains: true,
});

export const getPaymentDebitModeFilterConfig = () => ({
  source: field_data.payment_debit_mode,
  placeholder: form_data.payment_debit_mode,
  options: PAYMENT_DEBIT_MODE_OPTIONS,
  type: "dropdown",
  disableContains: true,
});

export const getOrgUuidFilterConfig = ({ staffMembers, showStaffMembers }) => ({
  source: "org_uuid",
  placeholder: form_data.staff,
  options: staffMembers,
  type: "dropdown",
  disableContains: true,
  hidden: !showStaffMembers,
});

export const getStartDateFilterConfig = () => ({
  source: "created_at__start_range",
  placeholder: form_data.start_date,
  type: "date",
});

export const getEndDateFilterConfig = () => ({
  source: "created_at__end_range",
  placeholder: form_data.end_date,
  type: "date",
});

export const getTransactionIdFilterConfig = () => ({
  source: field_data.transaction_id,
  placeholder: FILTERS_OPTIONS_PLACEHOLDERS[field_data.transaction_id],
  type: "input",
  disableContains: true,
  filterTriggerValidator: (value) => value && value?.trim().length >= 4,
  debounceTimeout: 3000,
});

export const getGatewayTypeFilterConfig = () => ({
  source: field_data.gateway_type,
  placeholder: FILTERS_OPTIONS_PLACEHOLDERS[field_data.gateway_type],
  disableContains: true,
  type: "dropdown",
  options: GATEWAY_TYPE_FILTER_OPTIONS,
});
