import { ExlyDropdown } from "common/form";
import { BILLING_STATE_OPTIONS } from "features/Common/constants/billingConstants";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { GST_FIELDS_FORM_VALIDATORS } from "features/Crm/modules/Bookings/modules/AddBookings/modules/AdvancedDetails/modules/GSTFields/GSTFields.utils";
import { PAYOUT_DETAILS_KEYS } from "features/Crm/modules/Bookings/modules/AddBookings/modules/AdvancedDetails/modules/hooks/usePayoutDetails/usePayoutDetails.constants";
import React from "react";
import { Field } from "react-final-form";
import { Label } from "schedule-v2/components/recorded-content/ExamCreation/components/HelperComponents/HelperComponents";
import { finalFormFieldArrayNameGenerator } from "utils/string";

const StateSelector = ({ payoutDetails }) => {
  const {
    [PAYOUT_DETAILS_KEYS.billing_state_non_mandatory]:
      isBillingStateNonMandatory,
  } = payoutDetails ?? {};

  return (
    <Field
      name={finalFormFieldArrayNameGenerator([
        ADD_CUSTOMER_FORM_KEYS.customer_gst_details,
        ADD_CUSTOMER_FORM_KEYS.billing_state,
      ])}
      component={ExlyDropdown}
      label={<Label label="State" required={!isBillingStateNonMandatory} />}
      placeholder="Select an Indian state"
      size="small"
      options={BILLING_STATE_OPTIONS}
      validate={GST_FIELDS_FORM_VALIDATORS[
        ADD_CUSTOMER_FORM_KEYS.billing_state
      ]({
        required: !isBillingStateNonMandatory,
      })}
      fullWidth
      tall
    />
  );
};

export default StateSelector;
