import React, { Suspense } from "react";

const InstalmentsFieldBase = React.lazy(() => import("./InstalmentsFieldBase"));

const InstalmentsField = (props) => {
  const { isInstalmentsPlanTypeSelected } = props;

  if (!isInstalmentsPlanTypeSelected) return null;

  return (
    <Suspense fallback={null}>
      <InstalmentsFieldBase {...props} />
    </Suspense>
  );
};

export default InstalmentsField;
