import { RESET_RESPONSES_PAYLOAD, SAVE_RESPONSES_PAYLOAD } from "./Crm.action";

const initialState = {
  responsesPayload: {
    entityType: null,
    actionType: null,
    uniqueId: null,
  },
};

const crmResponsesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_RESPONSES_PAYLOAD: {
      return {
        ...state,
        responsesPayload: {
          entityType: payload.entityType,
          actionType: payload.actionType,
          uniqueId: payload.uniqueId,
        },
      };
    }

    case RESET_RESPONSES_PAYLOAD: {
      return {
        ...state,
        responsesPayload: {
          entityType: null,
          actionType: null,
          uniqueId: null,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default crmResponsesReducer;
