import React from "react";

export const google_calendar_overview_constant = {
  title: "Overview",
  subTitle:
    "Google Calendar is an excellent cross-platform calendar app. It's simple to create new events whether you're on your phone or computer, and everything always stays synced no matter what time zone you're in. Plus, Google Calendar integrates with just about any other productivity app you can think of, so it will readily fit into your existing productivity system.",
};

export const google_calendar_installation_constant = {
  title: "Installation",
};

export const google_calendar_disconnection_constant = {
  title: "Disconnection",
};

export const options = {
  overview: {
    value: 0,
    label: "Overview",
  },
  installation: {
    value: 1,
    label: "Installation",
  },
  disconnection: {
    value: 2,
    label: "Disconnection",
  },
};

// local storage key to maintain whether the google calendar is synced or not
export const is_calendar_synced_local_storage_key = "calendar_sync";

export const google_calendar_overview_points = [
  {
    title: "Why you should sync with your google calendar?",
    points: [
      <>
        Managing schedules is hard enough when you have plenty of bookings
        spread over your entire week
      </>,
      <>
        Syncing your google calendar allows you to manage your offering
        schedules from within the Exly platform itself
      </>,
      <>Get notifications for upcoming schedules</>,
    ],
  },
];

export const google_calendar_installation_points = [
  {
    title: "Syncing your Google and Exly calendars",
    points: [
      <>Go to your Exly’s creator tool dashboard</>,
      <>Scroll down to the Exly calendar at the bottom</>,
      <>
        Click on the <b>Sync with Google Calendar</b> button
      </>,
      <>
        Choose the types of slots you would like to see on your Google calendar
      </>,
      <>Click on Save to save your settings</>,
      <>Your calendars will be synced upon saving</>,
      <>
        You can find the email ID linked with the Google calendar, at the top
        right
      </>,
    ],
  },
];
