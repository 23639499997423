import React from "react";
import cssStyles from "./MerchandiseVariantsChipsStyle.module.css";
import { is_empty } from "utils/validations";
import { getValuesFromVariantsString } from "./MerchandiseVariantsChips.utils";

const MerchandiseVariantsChips = ({ variants }) => {
  if (!variants) return "-";

  const vars = getValuesFromVariantsString(variants);

  if (is_empty(vars)) return "-";
  return (
    <div className={cssStyles.variantWrapper}>
      {vars?.map((variant) => {
        return (
          <div key={variant} className={cssStyles.chipStyle}>
            {variant}
          </div>
        );
      })}
    </div>
  );
};

export default MerchandiseVariantsChips;
