import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { initiate_calendar_connection } from "features/Integration/constants/Integration.apis";
import { is_calendar_synced_local_storage_key } from "../constants/GoogleCalendar.constants";

export const getGoogleCalendar = () =>
  dataProvider.custom_request(initiate_calendar_connection, apiMethods.POST, {
    return_url: `${window.location.href}`,
  });

// set google calendar after linking it to exly.
export const setGoogleCalendarSyncStatus = (isSync) => {
  setLocalStorageItem(is_calendar_synced_local_storage_key, isSync);
};

export const getGoogleCalendarSyncStatus = () => {
  return getLocalStorageItem(is_calendar_synced_local_storage_key);
};

// When google calendar is delinked then remove its status.
export const removeGoogleCalendarSyncStatus = () => {
  removeLocalStorageItem(is_calendar_synced_local_storage_key);
};
