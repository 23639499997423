import { app_route_ids, app_route_keys } from "constants/urlPaths";

export const MANAGE_BOOKINGS_RESPONSE_APIS = {
  [app_route_ids[app_route_keys.subscription_customer]]: ({ listData }) => {
    for (let i = 0; i < listData.length; i++) {
      let data = listData[i];
      data.id = data.transaction_uuid;
    }
    return { data: listData };
  },
  [app_route_ids[app_route_keys.listing_customer]]: ({ listData }) => {
    for (let i = 0; i < listData.length; i++) {
      let data = listData[i];
      data.id = data.transaction_uuid;
    }
    return { data: listData };
  },
};
