import { useEffect, useState } from "react";
import { fetchPayoutDetails } from "./usePayoutDetails.apiCalls";
import { logError } from "utils/error";
import { useToggleState } from "utils/hooks";

const usePayoutDetails = () => {
  const [payoutDetails, setPayoutDetails] = useState();
  const [loading, startLoading, stopLoading] = useToggleState(false);

  useEffect(() => {
    const handleError = (error) => {
      logError({
        error,
        when: "calling handleFetchPayoutDetails",
        occuredAt:
          "src/features/Bookings/modules/AddBookings/modules/AdvancedDetails/modules/hooks/usePayoutDetails/usePayoutDetails.js",
        severity: "blocker",
      });
    };

    const handleFetchPayoutDetails = async () => {
      if (loading) return;

      startLoading();

      fetchPayoutDetails()
        .then(setPayoutDetails)
        .catch(handleError)
        .finally(stopLoading);
    };

    handleFetchPayoutDetails();
  }, []);

  return { loading, payoutDetails };
};

export default usePayoutDetails;
