import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";

export const GST_RADIO_GROUP_ENABLED_OPTION = { label: "Yes", value: true };
export const GST_RADIO_GROUP_DISABLED_OPTION = {
  label: "No",
  value: false,
};

export const GST_RADIO_GROUP_OPTIONS = [
  GST_RADIO_GROUP_ENABLED_OPTION,
  GST_RADIO_GROUP_DISABLED_OPTION,
];

export const GST_FIELD_DATA = {
  gst_name_max_length: 30,
};

export const GST_FIELD_LABELS = {
  [ADD_CUSTOMER_FORM_KEYS.gst_number]: "GSTIN",
  [ADD_CUSTOMER_FORM_KEYS.gst_name]: "Registered company name",
};
