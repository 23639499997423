export const schedule_types_ids = {
  one_on_one_appointment: 0,
  one_on_one_class: 1,
  group_class: 2,
  workshop: 3,
  no_schedule: 4,
  content: 5,
  merchandise: 6,
  rolling_class: 7,
  telegram: 8,
  webinar: 9,
  whatsapp: 10,
  branded_community: 11,
};
