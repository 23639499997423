export const EXLY_WHATSAPP_STEPPER__WHATSAPP_APPLICATION_STATE =
  "@EXLY_WHATSAPP_STEPPER/WHATSPP_APPLICATION_STATE";

export const EXLY_INTEGRATION__DELINK_MODAL = "@EXLY_INTEGRATION__DELINK_MODAL";

export const EXLY_INTEGRATION__INTEGRATION_CONNECTION_STATUS =
  "@EXLY_INTEGRATION__INTEGRATION_CONNECTION_STATUS";

export const EXLY_INTEGRATION__INTEGRATION_SINGLE_CONNECTION_STATUS =
  "@EXLY_INTEGRATION__INTEGRATION_SINGLE_CONNECTION_STATUS";
