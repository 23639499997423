import {
  MY_BOOKINGS_ROUTE_IDS,
  MY_BOOKINGS_ROUTE_KEYS,
} from "../MyBookings.constants";

export const MY_BOOKINGS_RESPONSE_API = {
  [MY_BOOKINGS_ROUTE_IDS[MY_BOOKINGS_ROUTE_KEYS.MY_BOOKINGS_LIST]]: ({
    listData,
  }) => {
    for (let i = 0; i < listData.length; i++) {
      let data = listData[i];
      data.id = data.uuid;
      data.transaction_uuid = data.uuid;
    }
    return { data: listData };
  },
};
