import { useState } from "react";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import constants from "constants/constants";
import { useRefresh } from "react-admin";

const useMerchandiseShippingStatus = () => {
  const [processing, setProcessing] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isDispatchModalActive, showDispatchModal, hideDispatchModal] =
    useToggleState(false);
  const { notify } = useNotifications();

  const refresh = useRefresh();

  const handleShowShippingStatusUpdateModal = (record) => {
    if (record.dispatch_status !== constants.dispatchStatus.PENDING) return;

    setSelectedRecord(record);
    showDispatchModal();
  };

  /**
   * Handles the update of the shipping status for a merchandise booking.
   *
   * @async
   * @function handleUpdateShippingStatus
   * @param {Object} params - The parameters for updating the shipping status.
   * @param {Function} params.hideDetailsModal - Function to hide the modal.
   * @param {string} params.key - Key to identify the selected record.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const handleUpdateShippingStatus = async ({
    hideDetailsModal = () => {},
    key,
  }) => {
    try {
      if (processing) return;

      setProcessing(true);
      const status = await dataProvider.custom_request(
        api.merchandise_booking_update,
        apiMethods.POST,
        {
          booking_uid: selectedRecord[key] || selectedRecord.uuid,
          dispatch_status:
            parseInt(selectedRecord.dispatch_status) ===
            constants.dispatchStatus.PENDING
              ? constants.dispatchStatus.DISPATCHED
              : constants.dispatchStatus.PENDING,
        }
      );
      notify(
        "Shipping status updated successfully",
        notification_color_keys.success
      );
      if (isRequestSuccessful(status.status)) {
        hideDispatchModal();
        hideDetailsModal();
        refresh();
      }
    } catch (error) {
      logError({
        error,
        occuredAt: "handleUpdateShippingStatus in useMerchandiseShippingStatus",
        when: "calling handleUpdateShippingStatus",
      });
      notify(
        error.body?.message || SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };
  return {
    processing,
    isDispatchModalActive,
    hideDispatchModal,
    handleUpdateShippingStatus,
    handleShowShippingStatusUpdateModal,
  };
};

export default useMerchandiseShippingStatus;
