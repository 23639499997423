import { makeStyles } from "@material-ui/core/styles";

// TODO: move this code to module css
const useStyles = makeStyles(
  (theme) => ({
    desktopModalPaper: {
      width: "677px",
      maxWidth: "677px",
    },
    contentWrap: {
      padding: "20.5px 0 8px 0",
    },
    mb: {
      marginBottom: "20px",
    },
    mb_12: {
      marginBottom: "12px",
    },
    gap_24: {
      gap: "24px",
    },
    gap_12: {
      gap: "12px",
    },
    p_0: {
      padding: 0,
    },
    removeSvg: {
      fill: "#C31818",
    },
    borderBottom: {
      borderBottom: `1.5px solid ${theme.palette.secondary.shade_100}`,
    },
    imgWrap: {
      width: "77px",
      height: "79px",
      background: "#D9D9D9",
      borderRadius: "5px",
      overflow: "hidden",
    },
    listingImg: {
      width: "100%",
      height: "100%",
    },
    listingDescription: {
      flexGrow: 1,
      fontSize: "12px",
    },
    formTitle: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    addApointmentBtn: {
      marginTop: "18px",
      cursor: "pointer",
      marginBottom: "22px",
    },
    addSvg: {
      fill: "#36B37E",
      marginLeft: "14px",
    },
    errorMsg: {
      color: "#BF2600",
      fontSize: "11px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
