import { ExlyInput } from "common/form";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import React from "react";
import { Field } from "react-final-form";
import Divider from "../../../Divider/Divider";
import SectionContainer from "../../../SectionContainer/SectionContainer";
import TagsField from "./modules/TagsField/TagsField";

const TagsSection = () => {
  return (
    <SectionContainer label="ADD TAGS">
      <TagsField />

      <Divider />

      <Field
        name={ADD_CUSTOMER_FORM_KEYS.tag_name}
        component={ExlyInput}
        label="Create new tag"
        placeholder="Type a new tag"
        fullWidth
      />
    </SectionContainer>
  );
};

export default TagsSection;
