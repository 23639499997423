import { useState } from "react";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { customer_questions_constants } from "constants/customerQuestions.constants";
import { isRequestSuccessful } from "utils/Utils";
import { is_empty } from "utils/validations";
import { logError } from "utils/error";

const useCustomerStatus = (record) => {
  const { notify } = useNotifications();
  const [checkedState, setCheckedState] = useState(
    record.customer_schedule_status ===
      customer_questions_constants.customer_schedule_status.completed
  );
  const [alert, setAlert] = useState(false);
  const [isModalActive, showModal, hideModal] = useToggleState(false);

  const handleChangeSwitch = () => {
    setCheckedState(!checkedState);
    if (checkedState) {
      setAlert(true);
    } else {
      showModal();
    }
  };

  const onModalClose = () => {
    setCheckedState(!checkedState);
    setAlert(false);
    hideModal();
  };

  const onChangeCustomerStatus = async (formState) => {
    const transaction_uid = record.transaction_uuid;
    try {
      const fallbackString = `The session was marked ${
        checkedState ? "complete" : "incomplete"
      }`;
      const params = {
        status_change_message: is_empty(formState?.status_change_message)
          ? fallbackString
          : formState?.status_change_message,
        customer_schedule_status: checkedState
          ? customer_questions_constants.customer_schedule_status.completed
          : customer_questions_constants.customer_schedule_status
              .action_required,
        transaction_uid,
      };
      const apiResponse = await dataProvider.custom_request(
        api.schedule_management.set_schedule_status,
        apiMethods.POST,
        params
      );
      if (isRequestSuccessful(apiResponse.status)) {
        setCheckedState(alert ? false : true);
      }
      notify("Customer status changed successfully");
    } catch (error) {
      notify(error?.message, notification_color_keys.error);
      logError({
        error,
        when: "calling update schedule status api",
        occuredAt:
          "features/Crm/modules/Bookings/modules/CustomerStatus/utils/useCustomerStatus.js",
      });
    } finally {
      hideModal();
      setAlert(false);
    }
  };

  return {
    checkedState,
    alert,
    isModalActive,
    handleChangeSwitch,
    onModalClose,
    onChangeCustomerStatus,
  };
};

export default useCustomerStatus;
