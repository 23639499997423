import React, { Suspense } from "react";

const PlanTypeSelectorBase = React.lazy(() => import("./PlanTypeSelectorBase"));

const PlanTypeSelector = (props) => {
  const { isInstalmentsPlanTypeEnabled } = props;

  if (!isInstalmentsPlanTypeEnabled) return null;

  return (
    <Suspense fallback={null}>
      <PlanTypeSelectorBase {...props} />
    </Suspense>
  );
};

export default PlanTypeSelector;
