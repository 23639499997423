import React, { Suspense } from "react";

const GSTSectionBase = React.lazy(() => import("./GSTSectionBase"));

const GSTSection = ({ show }) => {
  if (!show) return null;

  return (
    <Suspense fallback={null}>
      <GSTSectionBase />
    </Suspense>
  );
};

export default GSTSection;
