/**
 * NOTE: dont use html, downloadable, hyperlink
 * TODO: replace these keys everywhere and remove
 */
export const RECORDED_CONTENT_MEDIA_TYPES = {
  video: 1,
  image: 2,
  text: 3,
  html: 3,
  attachment: 4,
  downloadable: 4,
  link: 5,
  hyperlink: 5,
  pdf: 6,
  embed: 7,
  exam: 8,
  form: 9,
  liveSession: 10,
  progress_tracking_form: 11,
};

export const RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS = {
  filter_all: 0,
  images: 2,
  videos: 1,
  other_files: `${RECORDED_CONTENT_MEDIA_TYPES.attachment},${RECORDED_CONTENT_MEDIA_TYPES.pdf}`,
};

export const RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP = {
  [RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.filter_all]: {
    id: "1",
    label: "Filter by: All",
    value: RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.filter_all,
  },
  [RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.images]: {
    id: "2",
    label: "Images",
    value: RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.images,
  },
  [RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.videos]: {
    id: "3",
    label: "Videos",
    value: RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.videos,
  },
  [RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.other_files]: {
    id: "4",
    label: "Files",
    value: RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.other_files,
  },
};

export const RECORDED_CONTENT_FILTER_VALUE_OPTIONS_LIST = [
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP[
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.videos
  ],
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP[
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.images
  ],
  RECORDED_CONTENT_FILTER_VALUE_OPTIONS_MAP[
    RECORDED_CONTENT_FILTER_VALUE_OPTION_KEYS.other_files
  ],
];
