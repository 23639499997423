import { maxLength, required } from "common/validate";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { NOTES_MAX_LENGTH } from "./AdvancedDetails.constants";

export const FORM_VALIDATORS = {
  [ADD_CUSTOMER_FORM_KEYS.note]: maxLength(
    NOTES_MAX_LENGTH,
    `Note should be less than ${NOTES_MAX_LENGTH} characters`
  ),
  [ADD_CUSTOMER_FORM_KEYS.payment_mode_name]: required,
};
