import {
  TRANSACTIONS_ROUTE_IDS,
  TRANSACTIONS_ROUTE_KEYS,
} from "../Transactions.constants";

export const TRANSACTIONS_RESPONSE_APIS = {
  [TRANSACTIONS_ROUTE_IDS[TRANSACTIONS_ROUTE_KEYS.TRANSACTIONS_LIST]]: ({
    listData,
  }) => {
    for (let i = 0; i < listData.length; i++) {
      let data = listData[i];
      data.id = data.uuid;
    }
    return { data: listData };
  },
};
