import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { is_empty } from "utils/validations";

/**
 * Fetches available appointment slots based on the provided query parameters.
 *
 * @async
 * @function showAppointmentSlots
 * @param {Object} queryParams - Query parameters to filter the appointment slots.
 * @returns {Promise<Object>} An object containing the available appointment slots.
 * @throws {Error} Throws an error if the request is unsuccessful.
 */
export const showAppointmentSlots = async ({ queryParams }) => {
  if (is_empty(queryParams)) return;

  const { data, status } = await dataProvider.custom_request(
    api.get_customer_slots,
    apiMethods.GET,
    queryParams
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error initiating bulk action`);

  const slots = data?.slots;
  return { slots };
};
