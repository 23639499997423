import { isInternationalTimezone } from "utils/AuthUtil";
import { isValidEmail, isValidMobile, is_empty } from "utils/validations";

export const validatePrice = (price, allValues) => {
  const { selected_quantity } = allValues;
  if (selected_quantity * price < 1) return "Invalid";
};

export const required = (value) => {
  if (is_empty(value)) return "Required";
};

export const validateEmail = (email) => {
  if (!isValidEmail(email)) return "Invalid Email";
};

export const validatePhoneNumber = (phoneNumber, allValues) => {
  const { country_code } = allValues;
  const _phone = phoneNumber
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .slice(country_code.length);
  if (!isInternationalTimezone() && !isValidMobile(country_code, _phone))
    return "Invalid Phone Number";
};
