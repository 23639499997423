import {
  custom_code,
  domain_linking,
  facebook_pixel,
  google_analytics,
  google_calendar,
  pabbly,
  shiprocket,
  whatsapp,
  wix,
  wordpress,
  zapier,
  zoom,
} from "../constants/Integration.constants";
import { green_tick_application_steps } from "../modules/WhatsappIntegrationSuccessView/constants/WhatsappIntegrationSuccessView.constants";
import {
  EXLY_INTEGRATION__DELINK_MODAL,
  EXLY_INTEGRATION__INTEGRATION_CONNECTION_STATUS,
  EXLY_INTEGRATION__INTEGRATION_SINGLE_CONNECTION_STATUS,
  EXLY_WHATSAPP_STEPPER__WHATSAPP_APPLICATION_STATE,
} from "./Integration.actions";

const initalValue = {
  whatsappApplicationState: green_tick_application_steps.GUIDE,
  is_delink_modal_open: false,
  integration_details: {
    [zapier]: undefined,
    [pabbly]: undefined,
    [whatsapp]: undefined,
    [wordpress]: undefined,
    [shiprocket]: undefined,
    [google_calendar]: undefined,
    [facebook_pixel]: undefined,
    [google_analytics]: undefined,
    [domain_linking]: undefined,
    [zoom]: undefined,
    [custom_code]: undefined,
    [wix]: undefined,
  },
};

const reducer = (state = initalValue, { type, payload }) => {
  switch (type) {
    case EXLY_WHATSAPP_STEPPER__WHATSAPP_APPLICATION_STATE: {
      return {
        ...state,
        whatsappApplicationState: payload,
      };
    }
    case EXLY_INTEGRATION__DELINK_MODAL: {
      return {
        ...state,
        is_delink_modal_open: payload,
      };
    }
    case EXLY_INTEGRATION__INTEGRATION_SINGLE_CONNECTION_STATUS: {
      return {
        ...state,
        integration_details: {
          ...state.integration,
          [payload?.integration_type]: payload?.integration_status,
        },
      };
    }
    case EXLY_INTEGRATION__INTEGRATION_CONNECTION_STATUS: {
      return {
        ...state,
        integration_details: { ...state.integration_details, ...payload },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
