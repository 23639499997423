import React from "react";
import { ReadMoreWrapper } from "@my-scoot/exly-react-component-lib";
import cssStyles from "./MerchandiseDeliveryAddressFieldStyles.module.css";

const MerchandiseDeliveryAddressField = ({ address }) => {
  if (!address) return "-";

  return (
    <ReadMoreWrapper
      childDivProps={{
        id: "text",
        className: cssStyles.textWrapper,
        dangerouslySetInnerHTML: { __html: address },
      }}
      collapsedHeight={70}
      ExpandToggleButton={({ onClick, isExpanded }) => (
        <div onClick={onClick} className={cssStyles.readMoreText}>
          {isExpanded ? "see less" : "...see more"}
        </div>
      )}
    ></ReadMoreWrapper>
  );
};

export default MerchandiseDeliveryAddressField;
