import { ExlyDropdown } from "common/form";
import React from "react";
import { Field, useFormState } from "react-final-form";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { is_empty } from "utils/validations";
import { getTagsDropdownOptions } from "../../TagsSection.utils";
import useTags from "../hooks/useTags/useTags";

const TagsField = () => {
  const {
    values: { [ADD_CUSTOMER_FORM_KEYS.tag_name]: tagName },
  } = useFormState();
  const { isLoading: isLoadingTags, tags } = useTags();

  return (
    <Field
      name={ADD_CUSTOMER_FORM_KEYS.tag_uuids}
      component={ExlyDropdown}
      options={getTagsDropdownOptions(tags)}
      label="Select from existing tags"
      placeholder="Select"
      size="small"
      loading={isLoadingTags}
      disabled={!is_empty(tagName)}
      multiple
      checked
      fullWidth
      tall
      showChips
    />
  );
};

export default TagsField;
