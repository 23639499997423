import React from "react";
import { is_empty } from "utils/validations";
import { Tooltip } from "@my-scoot/component-library-legacy";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import styles from "./BatchFieldStyles.module.css";

export const BatchField = ({ record = {}, source, listingData }) => {
  if (is_empty(listingData) || is_empty(record)) return "-";
  const batch =
    listingData?.classes?.filter((item) => item.uuid == record[source]) || [];
  const [firstBatch] = batch;
  return (
    <span>
      {is_empty(batch) ? (
        "-"
      ) : (
        <div className={styles.batchFieldContainer}>
          Batch {listingData.classes.indexOf(firstBatch) + 1}{" "}
          <Tooltip title={firstBatch?.timings} color="primary" arrow>
            <InfoOutlinedIcon className={styles.batchInfoIcon} />
          </Tooltip>
        </div>
      )}
    </span>
  );
};
