import constants from "constants/constants";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useExlyDataProvider } from "data/useExlyDataProvider";
import { BILLING_STATE_VALUE_LABEL_MAP } from "features/Common/constants/billingConstants";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { useRefresh } from "react-admin";
import { isInstallmentsPlanType } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/utils/PaymentPlanWithInstallments.utils";
import { ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD } from "ui/pages/customers/ManageBookings/constants";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { isClassType } from "utils/listing/listingUtils";
import { is_empty } from "utils/validations";
import { MODES_OF_PAYMENT } from "../../Bookings/modules/AddBookings/modules/AdvancedDetails/AdvancedDetails.constants";
import { postAddCustomer } from "../constants/AddCustomer.apiCalls";
import {
  formatBookingStartDate,
  getInstalmentToMarkAsPaid,
} from "./AddCustomer.utils";

export const useAddCustomer = ({ onClose, onSuccess }) => {
  const dataProvider = useExlyDataProvider();
  const refetch = useRefresh();
  const { notify } = useNotifications();

  const handleAddBooking = async (values) => {
    try {
      const {
        [ADD_CUSTOMER_FORM_KEYS.full_name]: fullName,
        [ADD_CUSTOMER_FORM_KEYS.email]: email,
        customer_phone_number,
        country_code,
        send_comms,
        selected_quantity,
        selected_price,
        currency,
        customer_timezone,
        [ADD_CUSTOMER_FORM_KEYS.address_details]: addressDetails,
        selected_batch,
        selected_appointment_slots,
        listing_data,
        booking_start_date,
        entity_data,
        offering_variant_uuid,
        country,
        [ADD_CUSTOMER_FORM_KEYS.payment_type]: paymentType,
        [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.installments]: instalments,
        [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.send_installment_comms]:
          sendInstalmentsComms = false,
        [ADD_CUSTOMER_FORM_KEYS.payment_mode_uuid]: paymentModeUuid,
        [ADD_CUSTOMER_FORM_KEYS.payment_mode_name]: paymentModeName,
        [ADD_CUSTOMER_FORM_KEYS.gateway_transaction_id]: gatewayTransactionId,
        [ADD_CUSTOMER_FORM_KEYS.customer_gst_details]: customerGstDetails,
        [ADD_CUSTOMER_FORM_KEYS.tag_uuids]: tagUuids,
        [ADD_CUSTOMER_FORM_KEYS.tag_name]: tagName,
        [ADD_CUSTOMER_FORM_KEYS.note]: note,
      } = values;

      const isInstalmentsPlanTypeSelected = isInstallmentsPlanType(paymentType);

      if (is_empty(listing_data)) {
        notify("No offering selected!", notification_color_keys.error);
        return;
      }

      const listingType = listing_data.type;

      let phoneNumber = customer_phone_number
        .replaceAll(" ", "")
        .replaceAll("-", "")
        .slice(country_code.length);
      let payload = {
        full_name: fullName,
        email,
        phone: phoneNumber || null,
        country_code: country_code,
        country,
        listing_uid: listing_data.uuid,
        send_comms: send_comms,
        quantity: selected_quantity,
        currency: currency,
        customer_timezone: customer_timezone?.value,
        [ADD_CUSTOMER_FORM_KEYS.payment_mode_uuid]:
          paymentModeUuid === MODES_OF_PAYMENT.OTHER
            ? undefined
            : paymentModeUuid,
        [ADD_CUSTOMER_FORM_KEYS.payment_mode_name]:
          paymentModeName?.trim() || undefined, // This is an optional field, don't include this key in the payload if the value is empty
        [ADD_CUSTOMER_FORM_KEYS.gateway_transaction_id]:
          gatewayTransactionId?.trim() || undefined, // This is an optional field, don't include this key in the payload if the value is empty
        [ADD_CUSTOMER_FORM_KEYS.tag_uuids]: tagName ? undefined : tagUuids, // This is an optional field, don't include this key in the payload if the value is empty
        [ADD_CUSTOMER_FORM_KEYS.tag_name]: tagName?.trim() || undefined, // This is an optional field, don't include this key in the payload if the value is empty
        [ADD_CUSTOMER_FORM_KEYS.note]: note,
      };

      if (customerGstDetails) {
        const {
          [ADD_CUSTOMER_FORM_KEYS.billing_state]: billingState,
          [ADD_CUSTOMER_FORM_KEYS.gst_name]: gstName,
          [ADD_CUSTOMER_FORM_KEYS.gst_number]: gstin,
        } = customerGstDetails;
        const customerGstDetailsPayload = {
          [ADD_CUSTOMER_FORM_KEYS.gst_name]: gstName?.trim() || undefined, // This is an optional field, don't include this key in the payload if the value is empty
          [ADD_CUSTOMER_FORM_KEYS.gst_number]: gstin?.trim() || undefined, // This is an optional field, don't include this key in the payload if the value is empty
          [ADD_CUSTOMER_FORM_KEYS.billing_state]: billingState
            ? BILLING_STATE_VALUE_LABEL_MAP[billingState]
            : undefined, // This is an optional field, don't include this key in the payload if the value is empty
        };
        payload[ADD_CUSTOMER_FORM_KEYS.customer_gst_details] =
          customerGstDetailsPayload;
      }

      if (isInstalmentsPlanTypeSelected) {
        payload = {
          ...payload,
          [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.installments]:
            getInstalmentToMarkAsPaid(instalments),
          [ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD.send_installment_comms]:
            sendInstalmentsComms,
        };
      } else {
        payload = {
          ...payload,
          amount: parseFloat(selected_quantity * selected_price),
          offering_variant_uuid,
        };

        if (listingType === constants.scheduleTypesId.workshop) {
          payload.slot_uids = listing_data.slots.map((item) => item.uuid);
        }

        const customerAddress = addressDetails?.trim();

        if (customerAddress) {
          payload[ADD_CUSTOMER_FORM_KEYS.address_details] = customerAddress;
        }

        const isClass = isClassType(listingType);
        const isNoSchedule =
          listingType === constants.scheduleTypesId.no_schedule;

        if (isClass || isNoSchedule) {
          if (booking_start_date)
            payload.booking_start_date =
              formatBookingStartDate(booking_start_date);
        }

        if (isClass) {
          payload.class_uid = selected_batch;
        }

        if (
          listingType === constants.scheduleTypesId.one_on_one_appointment ||
          listingType === constants.scheduleTypesId.webinar
        ) {
          payload.slot_uids = selected_appointment_slots
            .filter((item) => !is_empty(item.uuid))
            .map((item) => item.uuid);
        }

        if (entity_data && !is_empty(entity_data.uuid)) {
          payload.dropdown_plan = entity_data?.uuid;
        }
      }

      await postAddCustomer(
        dataProvider,
        payload,
        isInstalmentsPlanTypeSelected
      );
      refetch();
      if (onSuccess) onSuccess();
      onClose();
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error,
        when: "adding cash booking",
        occuredAt: "src/ui/modules/AddCustomerModal/useAddCustomer.js",
        severity: "blocker",
      });
    }
  };

  return {
    handleAddBooking,
  };
};
