import { is_empty } from "utils/validations";
import { OTHER_OF_PAYMENT_OPTION } from "../../AdvancedDetails.constants";

export const getPaymentModesOptions = (paymentModes) => {
  if (is_empty(paymentModes)) return [OTHER_OF_PAYMENT_OPTION];

  const validPaymentModes = paymentModes.map(
    ({ uuid: value, display_name: label }) => ({
      label,
      value,
    })
  );

  validPaymentModes.push(OTHER_OF_PAYMENT_OPTION);

  return validPaymentModes;
};
