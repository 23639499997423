import React from "react";
import useStyles from "../../AddCustomer.styles";
import usePayoutDetails from "features/Crm/modules/Bookings/modules/AddBookings/modules/AdvancedDetails/modules/hooks/usePayoutDetails/usePayoutDetails";
import ExlyModal from "common/Components/ExlyModal";
import { CheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry";
import { FEATURE_KEYS } from "common/Components/UBP/ubp.constants";
import ExlyLoader from "ui/modules/ExlyLoader";
import AddCustomer from "../../AddCustomer";
import { INITIAL_DATA_TYPE } from "../../constants/AddCustomer.constants";
import { useForm, useFormState } from "react-final-form";

const AddCustomerFormContent = ({
  open,
  onClose,
  listing_data: listingDataProp,
  entity_data: entityDataProp,
  listing_type: listingTypeProp,
  showOfferingSelector = true,
  handleSubmit,
  initialDataType,
  ...restFormProps
}) => {
  const classes = useStyles();
  const { loading, payoutDetails } = usePayoutDetails();
  const form = useForm();
  const { submitting } = useFormState();

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <ExlyModal
      open={open}
      onClose={handleClose}
      title={"Add a new booking"}
      onPrimaryBtnClick={handleSubmit}
      primaryBtnText={"Add Booking"}
      primaryBtnProps={{
        type: "submit",
        disabled: submitting,
        loading: submitting,
      }}
      modal_props={{
        modalPaperClassName: classes.desktopModalPaper,
      }}
      mobile_modal_props={{}}
      className={`p-0`}
    >
      <>
        <CheckCreditExpiry feature_key={FEATURE_KEYS.cash_upload_gmv.key} />
        <div className={`px-3`}>
          {loading ? (
            <ExlyLoader />
          ) : (
            <AddCustomer
              {...restFormProps}
              isPlan={initialDataType === INITIAL_DATA_TYPE.PLAN}
              showOfferingSelector={showOfferingSelector}
              payoutDetails={payoutDetails}
              initialDataType={initialDataType}
              listingTypeProp={listingTypeProp}
              listingDataProp={listingDataProp}
              entityDataProp={entityDataProp}
            />
          )}
        </div>
      </>
    </ExlyModal>
  );
};

export default AddCustomerFormContent;
