/**
 * Function to extract values from a comma-separated key-value string.
 * @param {string} inputString - The input string containing key-value pairs.
 * @returns {Array} - An array of values extracted from the input string.
 */
export const getValuesFromVariantsString = (inputString) => {
  // Split the string by commas to get key-value pairs
  const cleanedString = inputString?.replace(/^\(|\)$/g, "");
  const keyValuePairs = cleanedString?.split(",");

  const keyValueObject = {};
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split(":").map((str) => str.trim());
    keyValueObject[key] = value;
  });

  const values = Object.values(keyValueObject);

  return values;
};
