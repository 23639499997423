import React from "react";
import ShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import classnames from "classnames";
import { shipping_status_options } from "../../MerchandiseShippingStatus.constants";
import styles from "../../MerchandiseShippingStatusDropdownStyles.module.css";

const ShippingStatusOptions = () => {
  return shipping_status_options?.map((statusOption) => {
    return {
      label: (
        <span
          className={classnames(
            styles.menuOption,
            styles[statusOption.className]
          )}
        >
          <ShippingIcon className={styles[statusOption.className]} />
          &nbsp;{statusOption.label}
        </span>
      ),
      value: statusOption.value,
    };
  });
};

export default ShippingStatusOptions;
