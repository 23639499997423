import React, { useState } from "react";
import { is_empty } from "utils/validations";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import { Field, useFormState } from "react-final-form";
import { ExlyInput } from "common/form";
import styles from "../QuestionTypeValidationStyles.module.css";
import QuestionTypeValidationConstants from "../QuestionTypeValidations.constants.js";

const {
  form_fields: { number },
} = QuestionTypeValidationConstants;

const NumberQuestionTypeValidation = () => {
  const formState = useFormState();
  const [allowLimit, setAllowLimit] = useState(
    !is_empty(formState?.values?.questionObj?.validations)
  );

  const validate = (value, allValues, meta) => {
    const { validations } = allValues.questionObj;
    if (meta.name === number.min && Number(value) >= Number(validations?.max)) {
      return "Minimum value should be less than the maximum value";
    } else if (
      meta.name === number.max &&
      Number(value) <= Number(validations?.min)
    ) {
      return "Maximum value should be greater than the minimum value";
    }
  };

  return (
    <div>
      <Field
        name={number.set_limits}
        component={ExlyCheckbox}
        size="large"
        fullWidth
        label={"Set limits"}
        checked={allowLimit}
        onChange={(value) => setAllowLimit(value)}
      />
      {allowLimit && (
        <>
          <div className={styles.fieldWrapper}>
            <div className={styles.questionsTitle}>Minimum Value</div>
            <Field
              name={number.min}
              placeholder="Minimum value allowed"
              type="number"
              size="small"
              className="w-100"
              component={ExlyInput}
              validate={validate}
            />
          </div>
          <div className={styles.fieldWrapper}>
            <div className={styles.questionsTitle}>Maximum Value</div>
            <Field
              name={number.max}
              placeholder="Maximum value allowed"
              type="number"
              size="small"
              className="w-100"
              component={ExlyInput}
              validate={validate}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NumberQuestionTypeValidation;
