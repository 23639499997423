import SessionStorageConstants from "constants/SessionStorage.constants";

export const REFUND_URL_KEYS = {
  review_selection_modal: "review_selection_modal",
};
export const REFUND_URL_IDS = {
  [REFUND_URL_KEYS.review_selection_modal]: "review-selection-modal",
};

export const REFUND_PAGES = {
  reviewSelection: "reviewSelection",
  initiateRefund: "initiateRefund",
};

export const APIS = {
  post_initiate_refund: "payments/review/refund/details",
  refund_transactions_list: "payments/refund/transactions/list",
};

export const REFUND_RESOURCE_APIS = {
  [REFUND_URL_IDS[REFUND_URL_KEYS.review_selection_modal]]: () => {
    const request_id = sessionStorage.getItem(
      SessionStorageConstants.REFUND_REQUEST_ID
    );
    return {
      api_end_point: APIS.refund_transactions_list,
      queries: { request_id },
    };
  },
};
