export const CASH_PAYMENT_GATEWAY = "Cash";
export const BAJAJ_FINANCE_PAYMENT_GATEWAY = "BFL";

export const formKeys = {
  transactionType: {
    label: "Transaction type",
    key: "transaction_type",
  },
};

export const TRANSACTION_TYPE_ENUM = {
  online: 1,
  offline: 2,
};

export const TRANSACTION_TYPES = {
  [TRANSACTION_TYPE_ENUM.online]: {
    value: TRANSACTION_TYPE_ENUM.online,
    label: "Online",
  },
  [TRANSACTION_TYPE_ENUM.offline]: {
    value: TRANSACTION_TYPE_ENUM.offline,
    label: "Offline",
  },
};

export const GATEWAY_TYPE = {
  EXLY: 1,
  EXTERNAL: 2,
};

export const GATEWAY_TYPE_OPTIONS_MAP = {
  [GATEWAY_TYPE.EXLY]: { label: "Exly Bookings", value: GATEWAY_TYPE.EXLY },
  [GATEWAY_TYPE.EXTERNAL]: {
    label: "External Bookings",
    value: GATEWAY_TYPE.EXTERNAL,
  },
};

export const GATEWAY_TYPE_FILTER_OPTIONS = [
  GATEWAY_TYPE_OPTIONS_MAP[GATEWAY_TYPE.EXLY],
  GATEWAY_TYPE_OPTIONS_MAP[GATEWAY_TYPE.EXTERNAL],
];

export const TRANSACTION_LABEL = "transaction";

export const TRANSACTIONS_ROUTE_KEYS = {
  TRANSACTIONS_LIST: "transactions",
};

export const TRANSACTIONS_ROUTE_IDS = {
  [TRANSACTIONS_ROUTE_KEYS.TRANSACTIONS_LIST]:
    "payments/exly/transactions/list",
};
